import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Fade } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const CopyToClipboardWrapper = ({ text, imgSrc, buttonMessage }) => {
  const [fadeIn, setFadeIn] = useState(false);
  const [failed, setFailed] = useState(false);

  const onCopy = (text, success) => {
    setFailed(!success);
    setFadeIn(true);
    setTimeout(() => {
      setFadeIn(false);
    }, 1750);
  };

  return (
    <>
      <CopyToClipboard text={text} onCopy={onCopy}>
        <Button color="primary">
          <img src={imgSrc} alt="" />
          {buttonMessage}
        </Button>
      </CopyToClipboard>
      <Fade in={fadeIn}>
        {failed ? (
          <div className="copied hover-card failed">
            <i className="zmdi zmdi-block" />
            <FormattedMessage id="landing.voucher.copied.failed" />
          </div>
        ) : (
          <div className="copied hover-card success">
            <i className="zmdi zmdi-check" />
            <FormattedMessage id="landing.voucher.copied.success" />
          </div>
        )}
      </Fade>
    </>
  );
};

CopyToClipboardWrapper.propTypes = {
  text: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  buttonMessage: PropTypes.node.isRequired
};

export default CopyToClipboardWrapper;
