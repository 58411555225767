import React from 'react';
import axios from 'axios';

import { StateProvider, useStateValue } from '../../utils/state';

const LOAD_PROJECTS = 'LOAD_PROJECTS';
const LOAD_PROJECT = 'LOAD_PROJECT';
const LOAD_PROJECT_VOUCHERS = 'LOAD_PROJECT_VOUCHERS';
const ADD_PROJECT = 'ADD_PROJECT';
const EDIT_PROJECT = 'EDIT_PROJECT';
const DELETE_PROJECT = 'DELETE_PROJECT';
const PROJECT_ERROR = 'PROJECT_ERROR';

export const initialState = {
  projects: {
    content: []
  },
  project: {},
  errors: {}
};

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case LOAD_PROJECTS:
      return {
        ...state,
        projects: payload
      };
    case LOAD_PROJECT:
      return {
        ...state,
        project: {
          ...payload,
          vouchers: (state.project || {}).vouchers
        }
      };
    case LOAD_PROJECT_VOUCHERS:
      return {
        ...state,
        project: {
          ...state.project,
          vouchers: payload
        }
      };
    case ADD_PROJECT:
      return {
        ...state,
        project: payload,
        projects: {
          ...state.projects,
          content: [payload, ...state.projects.content],
          total: state.projects.total + 1
        }
      };
    case EDIT_PROJECT:
      return {
        ...state,
        project: {
          ...payload,
          vouchers: (state.project || {}).vouchers
        },
        projects: {
          ...state.projects,
          content: state.projects.content.map(entry => {
            if (entry._id === payload._id) {
              return payload;
            }
            return entry;
          })
        }
      };
    case DELETE_PROJECT:
      return {
        ...state,
        project: {
          ...payload,
          vouchers: (state.project || {}).vouchers
        },
        projects: {
          ...state.projects,
          content: state.projects.content.filter(({ _id }) => _id !== payload._id),
          total: state.projects.total - 1
        }
      };
    case PROJECT_ERROR:
      return {
        ...state,
        errors: payload
      };

    default:
      return state;
  }
};

export const loadProjects = dispatch => {
  axios
    .get('/api/projects?results=100&includeDeleted=true')
    .then(({ data }) => dispatch({ type: LOAD_PROJECTS, payload: data }));
};

export const loadProject = (projectSlug, dispatch) => {
  axios
    .get(`/api/projects/${projectSlug}`)
    .then(({ data }) => dispatch({ type: LOAD_PROJECT, payload: data }));
};

export const getVoucherCounts = (projectSlug, dispatch) => {
  axios
    .get(`/api/projects/${projectSlug}/vouchers/count`)
    .then(({ data }) => dispatch({ type: LOAD_PROJECT_VOUCHERS, payload: data }));
};

export const deleteProject = (slug, dispatch) => {
  axios
    .delete(`/api/projects/${slug}`)
    .then(({ data }) => dispatch({ type: DELETE_PROJECT, payload: data }));
};

export const restoreProject = (slug, dispatch) => {
  axios
    .put(`/api/projects/${slug}/restore`)
    .then(({ data }) => dispatch({ type: EDIT_PROJECT, payload: data }));
};

export const addProject = (data, file, dispatch) => {
  dispatch({ type: PROJECT_ERROR, payload: {} });
  axios
    .post('/api/projects', data)
    .then(result => (file ? axios.put(`/api/projects/${data._id}/icon`, file) : result))
    .then(({ data }) => dispatch({ type: ADD_PROJECT, payload: data }))
    .catch(({ response }) => dispatch({ type: PROJECT_ERROR, payload: response && response.data }));
};

export const editProject = (data, file, dispatch) => {
  dispatch({ type: PROJECT_ERROR, payload: {} });
  axios
    .put(`/api/projects/${data._id}`, data)
    .then(result => (file ? axios.put(`/api/projects/${data._id}/icon`, file) : result))
    .then(({ data }) => dispatch({ type: EDIT_PROJECT, payload: data }))
    .catch(({ response }) => dispatch({ type: PROJECT_ERROR, payload: response && response.data }));
};

export const useProjectsState = useStateValue;

export const Provider = ({ children }) => (
  <StateProvider initialState={initialState} reducer={reducer}>
    {children}
  </StateProvider>
);
