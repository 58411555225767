import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';

import { useProjectsState, getVoucherCounts } from '../actions';

const Vouchers = ({ projectSlug }) => {
  const [
    {
      project: { vouchers = [], countries = [] }
    },
    dispatch
  ] = useProjectsState();

  useEffect(() => {
    getVoucherCounts(projectSlug, dispatch);
  }, [dispatch, projectSlug]);

  return (
    <>
      <p>Vouchers</p>
      <Table responsive hover>
        <thead>
          <tr>
            <th scope="col">Country</th>
            <th scope="col">Open</th>
            <th scope="col">Total</th>
          </tr>
        </thead>
        <tbody>
          {vouchers.map(({ key, open, total }) => {
            const country = countries.find(country => country.key === key) || { en: key };
            return (
              <tr key={key}>
                <th scope="row">{country.en}</th>
                <td>{open}</td>
                <td>{total}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

Vouchers.propTypes = {
  projectSlug: PropTypes.string.isRequired
};

export default Vouchers;
