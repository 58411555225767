import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import External from './External';
import Internal from './Internal';

const BaseLayout = ({ isAuthenticated }) => (isAuthenticated ? <Internal /> : <External />);

BaseLayout.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
};

export default withRouter(BaseLayout);
