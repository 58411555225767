import React, { createContext, useContext, useReducer } from 'react';

import { IntlProvider } from 'react-intl';

import messagesEn from './translations/en.json';
import messagesDe from './translations/de.json';
import messagesDeFormally from './translations/de.formally.json';

const overrideKeys = window.location.search.substr(1).split('&').map(entry => {
    const [key, value] = entry.split('=');
    return {key, value};
}).filter(({value}) => value !== undefined);

const messagesMap = {
  de: messagesDe,
  'de.formally': messagesDeFormally,
  en: messagesEn
};

let languageOverride;
const { search } = window.location;
if (search) {
  if (search.indexOf('lang=de') !== -1) {
    languageOverride = 'de';
  } else if (search.indexOf('lang=en') !== -1) {
    languageOverride = 'en';
  }
  if (languageOverride) {
    localStorage.lang = languageOverride;
  }
}
if (!languageOverride && localStorage.lang) {
  languageOverride = localStorage.lang;
}

const isFormally = () => localStorage.lang_formally === 'true';

const getMessages = lang =>
  messagesMap[lang + (isFormally() ? '.formally' : '')] || messagesMap[lang];

const language =
  languageOverride ||
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;
const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

let messages = getMessages(languageWithoutRegionCode);
localStorage.lang = languageWithoutRegionCode;
if (!messages) {
  localStorage.lang = language;
  messages = getMessages(language);
}
if (!messages) {
  localStorage.lang = 'en';
  messages = getMessages(localStorage.lang);
}

const initialState = { language: localStorage.lang, formally: isFormally(), messages };

const reducer = (state, { language, formally = false }) => {
  localStorage.lang = language;
  localStorage.lang_formally = formally;
  let messages = getMessages(language);
  if (!messages) {
    localStorage.lang = 'en';
    messages = getMessages(localStorage.lang);
  }
  return { ...state, language, formally, messages };
};

const StateContext = createContext([initialState, reducer]);

const Inner = ({ children }) => {
  const [{ language, messages }] = useLanguage();
  overrideKeys.forEach(({key, value}) => {
    messages[key] = decodeURI(value);
  })
  return (
    <IntlProvider locale={language} messages={messages}>
      {children}
    </IntlProvider>
  );
};

const Localization = ({ children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    <Inner>{children}</Inner>
  </StateContext.Provider>
);

export const useLanguage = () => useContext(StateContext);

export default Localization;
