import axios from 'axios';

const header = 'x-request-id';
axios.interceptors.response.use(response => {
  const requestId = response.headers[header];
  if (requestId) {
    axios.defaults.headers.common[header] = requestId;
  }
  return response;
});
