import React from 'react';

import { useLoginState, logoutUser } from '../../actions';

const Logout = props => {
  const [{ isAuthenticated }, dispatch] = useLoginState();

  if (isAuthenticated) {
    logoutUser(dispatch);
  }
  return <></>;
};

export default Logout;
