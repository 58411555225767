import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import axios from 'axios';

import { useLanguage } from '../../localization';

import LanguageSelection from './base/LanguageSelection';
import Start from './body/Start';
import Tutorial from './body/Tutorial';
import VoucherOnly from './body/VoucherOnly';

const api = {
  loadProject: projectSlug => axios.get(`/api/projects/${projectSlug}`).then(({ data }) => data),

  claimVoucher: (projectSlug, country = undefined) =>
    axios
      .get(`/api/projects/${projectSlug}/voucher${country ? `?country=${country}` : ''}`)
      .then(({ data }) => data)
      .catch(() => false)
};

const Header = () => (
  <div className="header">
    <img
      src="/images/coyo-logo-black.png"
      srcSet="/images/coyo-logo-black@2x.png 2x, /images/coyo-logo-black@3x.png 3x"
      alt="COYO Logo"
      className="coyo-brand"
    />
    <LanguageSelection />
  </div>
);

const footerLink = (id, formatMessage) => (
  <a href={formatMessage({ id: `${id}.link` })} target="_blank" rel="noopener noreferrer">
    {formatMessage({ id: `${id}.text` })}
  </a>
);

const Footer = injectIntl(({ intl: { formatMessage } }) => (
  <div className="footer">
    {footerLink('imprint', formatMessage)}
    {footerLink('privacy-policy', formatMessage)}
  </div>
));

const ViewProject = ({
  match: {
    params: { id: projectSlug }
  },
  location: { search }
}) => {
  const voucherOnly = search.indexOf('voucherOnly') !== -1;
  const [clicked, setClicked] = useState(false);
  const [project, setProject] = useState({});
  const [{ language, formally }, changeLanguage] = useLanguage();

  useEffect(() => {
    api.loadProject(projectSlug).then(project => {
      if (project.formallyLanguage !== formally) {
        changeLanguage({ language, formally: project.formallyLanguage });
      }
      setProject(project);
    });
  }, [changeLanguage, formally, language, projectSlug]);

  if (project.slug !== projectSlug) {
    return null;
  }

  const { name, slug, contact, androidStoreLink, appIcon, countries } = project;

  if (voucherOnly) {
    return (
      <div className="landing tutorial">
          <VoucherOnly
            slug={slug}
            claimVoucher={api.claimVoucher}
            countries={countries}
          />
      </div>
    );
  }

  let content;
  if (clicked) {
    content = (
      <Tutorial
        name={name}
        slug={slug}
        contact={contact}
        claimVoucher={api.claimVoucher}
        countries={countries}
      />
    );
  } else {
    content = (
      <Start
        name={name}
        slug={slug}
        appIcon={appIcon}
        androidStoreLink={androidStoreLink}
        onClick={() => setClicked(true)}
      />
    );
  }

  return (
    <div className={`landing ${clicked ? 'tutorial' : 'start'}`}>
      <Header />
      {content}
      <Footer />
    </div>
  );
};

export default ViewProject;
