import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

const Header = ({ title, back, actions }) => {
  return (
    <div className="nav">
      <span className="title">{title}</span>
      <div>
        {!!actions &&
          actions.map(({ name, action, onClick, color = 'info' }) =>
            action ? (
              <span key={name}>{action}</span>
            ) : (
              <Button key={name} onClick={onClick} color={color} className="btn-sm">
                {name}
              </Button>
            )
          )}
        {!!back && (
          <Link to="/" title="Back">
            <span className="btn btn-sm btn-primary">Back</span>
          </Link>
        )}
        <Link to="/logout" title="Logout">
          <span className="btn btn-sm btn-primary">
            <i className="zmdi zmdi-square-right" />
          </span>
        </Link>
      </div>
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.any.isRequired,
  actions: PropTypes.array,
  back: PropTypes.bool
};

Header.defaultProps = {
  actions: undefined,
  back: false
};

export default Header;
