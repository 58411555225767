import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Card, CardBody, CardTitle, Col, Form, FormGroup, Label, Input } from 'reactstrap';

import { useLoginState, loginUser } from '../../../actions';

import '../../../styles/Auth.scss';

const Alert = ({ type, text, title }) => (
  <span className={`alert alert-${type} d-block`}>
    <i className="zmdi zmdi-alert-circle-o" />
    {!!title && <span className="title">{title}</span>}
    <span className="text">{text}</span>
  </span>
);

const Errors = ({ warning, errors, translate }) => {
  let content;
  if (warning) {
    const key = 'error.credentials.missing';
    content = (
      <Alert
        type="warning"
        text={translate(`${key}.${warning}`)}
        title={translate(`${key}.title`)}
      />
    );
  } else {
    content = Object.keys(errors).map(key => {
      const error = translate(errors[key]);
      return <Alert type="danger" key={key} text={error} />;
    });
  }
  return <div className="auth-errors">{content}</div>;
};

const Field = ({
  name,
  data,
  onChange,
  label,
  type,
  errors,
  autoFocus = false,
  placeholder = ''
}) => {
  return (
    <Col>
      <FormGroup>
        <Label for={name}>{label}</Label>
        <div className={`${name}-field`}>
          <Input
            autoFocus={autoFocus}
            name={name}
            id={name}
            defaultValue={data[name]}
            onChange={onChange}
            className={errors[name] ? 'invalid' : ''}
            placeholder={placeholder}
            type={type}
          />
        </div>
      </FormGroup>
    </Col>
  );
};

const Login = ({ intl: { formatMessage } }) => {
  const [state, setState] = useState({});
  const [{ isAuthenticated, errors }, dispatch] = useLoginState();

  if (isAuthenticated) {
    return null;
  }

  const translate = id => formatMessage({ id });

  const onSubmit = e => {
    e.preventDefault();
    const { email, password } = state;
    const normalizedEmail = (email || '').trim().toLowerCase();
    if (normalizedEmail && password) {
      setState(state => ({ ...state, error: false, submitted: true }));
      loginUser(normalizedEmail, password, dispatch);
    } else {
      setState(state => ({ ...state, error: 'login', submitted: false }));
    }
  };

  const onChange = ({ target: { name, value } }) =>
    setState(state => ({ ...state, [name]: value }));

  const props = { data: state, errors, onChange };

  return (
    <div className="auth">
      <div className="wrapper">
        <Card className="auth-form mx-auto">
          <CardBody className="welcome-header text-center">
            <CardTitle>{translate('auth.login.title')}</CardTitle>
          </CardBody>
          <CardBody className="welcome-body">
            <Errors warning={state.error} errors={errors} translate={translate} />
            <Form onSubmit={onSubmit} className="login-form">
              <Field
                name="email"
                type="text"
                autoFocus
                label={translate('auth.email.name')}
                placeholder={translate('auth.email.placeholder')}
                {...props}
              />
              <Field
                name="password"
                type="password"
                label={translate('auth.password.name')}
                placeholder="********"
                {...props}
              />
              <Col className="buttons text-right">
                <Button color="primary" block>
                  {translate('auth.login.submit')}
                </Button>
              </Col>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default injectIntl(Login);
