import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Login from '../external/base/Login';
import Landing from '../external/Landing';
import ViewProject from '../external/ViewProject';

import NotFound from '../errors/NotFound';
import Forbidden from '../errors/Forbidden';
import InternalServer from '../errors/InternalServer';

import '../../styles/External.scss';

const External = () => {
  return (
    <div className="content external">
      <Switch>
        <Route exact path="/not-found" component={NotFound} />
        <Route exact path="/internal-server" component={InternalServer} />
        <Route exact path="/forbidden" component={Forbidden} />

        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" render={() => <Redirect to="/login" />} />

        <Route exact path="/:id" component={ViewProject} />

        <Route exact path="/" component={Landing} />

        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </div>
  );
};

export default External;
