import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Input, Label, Table } from 'reactstrap';

import { loadProjects, useProjectsState } from '../actions';

const ProjectList = ({ history }) => {
  const [search, setSearch] = useState('');
  const [showDeleted, setShowDeleted] = useState(false);

  const [
    {
      projects: { content: projects = [], total = 0 }
    },
    dispatch
  ] = useProjectsState();

  useEffect(() => {
    loadProjects(dispatch);
  }, [dispatch]);

  if (!projects.length) {
    return <div className="text-center text-muted">No projects yet</div>;
  }

  const list = projects
    .filter(({ deletedAt }) => showDeleted || !deletedAt)
    .filter(({ name }) => !search || name.indexOf(search) !== -1);

  let content;
  if (!list.length) {
    content = <div className="text-center text-muted">No projects found</div>;
  } else {
    const count = list.reduce(
      (counts, { deletedAt }) => {
        if (deletedAt) {
          counts.deleted++;
        } else {
          counts.projects++;
        }
        return counts;
      },
      { projects: 0, deleted: 0 }
    );
    content = (
      <>
        <Table responsive hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Slug</th>
              <th>Contact</th>
              <th>Android store link</th>
              {showDeleted && <th>Deleted</th>}
            </tr>
          </thead>
          <tbody>
            {list.map(({ _id, name, slug, contact, androidStoreLink, deletedAt }, index) => (
              <tr
                key={slug}
                className={deletedAt ? 'text-muted' : ''}
                onClick={() => history.push(`/${slug}`)}
              >
                <th scope="row">{index + 1}</th>
                <td>{name}</td>
                <td>{slug}</td>
                <td>{contact}</td>
                <td>{androidStoreLink}</td>
                {showDeleted && <td>{deletedAt ? 'Yes' : 'No'}</td>}
              </tr>
            ))}
          </tbody>
        </Table>

        <div className="text-right">
          {count.projects} projects ({count.deleted} deleted) - Total: {total}
        </div>
      </>
    );
  }

  return (
    <div className="projects-list">
      <div className="text-center mb-3">
        <Label>
          <Input
            type="text"
            className="text-center"
            value={search}
            onChange={({ target: { value } }) => setSearch(value)}
            placeholder="Search project"
            aria-label="Search project"
          />
        </Label>

        <Label check className="ml-5">
          <Input
            type="checkbox"
            value={showDeleted}
            onChange={() => setShowDeleted(!showDeleted)}
          />
          Show deleted
        </Label>
      </div>
      {content}
    </div>
  );
};

export default withRouter(ProjectList);
