import React, { useState, useEffect } from 'react';

import { useLanguage } from '../../../localization';

const languages = ['en', 'de'];

const className = 'language-selection';
const className2 = 'current';

const clickedOutside = (node, open) => {
  if (
    node.parentNode &&
    node.parentNode.className === className2 &&
    (node.parentNode.parentNode || {}).className === className
  ) {
    return open;
  }
  while (node.parentNode) {
    if (node.parentNode.className === className) {
      return false;
    }
    node = node.parentNode;
  }
  return true;
};

const LanguageSelection = () => {
  const [open, setOpen] = useState(false);
  const [{ language }, changeLanguage] = useLanguage();

  useEffect(() => {
    const handler = e => setOpen(open => !clickedOutside(e.target, open));
    document.addEventListener('click', handler);
    return () => document.removeEventListener('click', handler);
  }, []);

  return (
    <div className={className}>
      <div className={className2}>
        <span>{language}</span> <i className="zmdi zmdi-chevron-down" />
      </div>
      {!!open && (
        <div className="dropdown">
          {languages
            .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
            .map(lang => (
              <div
                key={lang}
                onClick={() => {
                  changeLanguage({ language: lang });
                  setOpen(false);
                }}
                className={lang === language ? 'active' : ''}
              >
                {lang}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelection;
