import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

import ManageProject from './ManageProject';

const ManageProjectModal = ({ edit, className }) => {
  const [visible, setVisible] = useState(false);

  const toggle = () => setVisible(visible => !visible);

  const label = edit ? 'Edit project' : 'Add project';
  return (
    <>
      <Button onClick={() => setVisible(true)} color="primary" className={className}>
        {label}
      </Button>
      <Modal isOpen={visible} toggle={toggle} backdrop="static" centered>
        <ModalHeader
          toggle={toggle}
          close={
            <button type="button" className="close" onClick={toggle}>
              <i className="zmdi zmdi-close" />
            </button>
          }
        >
          {label}
        </ModalHeader>
        <ModalBody>
          <ManageProject edit={edit} close={() => setVisible(false)} />
        </ModalBody>
      </Modal>
    </>
  );
};

ManageProjectModal.propTypes = {
  edit: PropTypes.object,
  className: PropTypes.string
};

ManageProjectModal.defaultProps = {
  edit: undefined,
  className: undefined
};

export default ManageProjectModal;
