import React from 'react';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

import { StateProvider, useStateValue } from './utils/state';
import setAuthToken from './utils/setAuthToken';

const LOGIN = 'LOGIN';
const LOGIN_ERRORS = 'LOGIN_ERRORS';

const initialState = {
  user: {},
  isAuthenticated: false,
  errors: {}
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case LOGIN:
      return {
        ...state,
        user: payload,
        isAuthenticated: !!Object.keys(payload).length,
        errors: {}
      };
    case LOGIN_ERRORS:
      return {
        ...state,
        user: {},
        isAuthenticated: false,
        errors: payload
      };

    default:
      return state;
  }
};

export const loginUser = (email, password, dispatch) => {
  axios
    .post('/api/login', { email, password })
    .then(({ data }) => {
      const { success, token } = data;
      if (success) {
        const decoded = jwtDecode(token);
        localStorage.jwtToken = token;
        setAuthToken(token, decoded);
        dispatch({ type: LOGIN, payload: decoded });
      } else {
        dispatch({
          type: LOGIN_ERRORS,
          payload: { error: 'Error: Please try again later.' }
        });
      }
    })
    .catch(({ response }) => dispatch({ type: LOGIN_ERRORS, payload: response.data }));
};

export const logoutUser = dispatch => {
  localStorage.removeItem('jwtToken');
  setAuthToken(false);
  dispatch({
    type: LOGIN_ERRORS,
    payload: { error: 'Logout successful' }
  });
};

export const checkAuthentication = dispatch => {
  if (localStorage.jwtToken) {
    const decoded = jwtDecode(localStorage.jwtToken);
    setAuthToken(localStorage.jwtToken, decoded);
    dispatch({ type: LOGIN, payload: decoded });

    // Check for token expiry
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      logoutUser(dispatch);
      return false;
    }
    return true;
  }
  return false;
};

export const useLoginState = useStateValue;

export const Provider = ({ children }) => (
  <StateProvider initialState={initialState} reducer={reducer}>
    {children}
  </StateProvider>
);
