import React from 'react';
import IntlPolyfill from 'react-intl-polyfill';

import Localization from './localization';
import Router from './utils/router';
import './utils/catchUnhandled';
import './utils/requestId';

import { Provider, useLoginState, checkAuthentication } from './actions';

import './styles/App.scss';
import BaseLayout from './components/layout/BaseLayout';

const Authentication = () => {
  const [{ isAuthenticated }, dispatch] = useLoginState();
  if (!isAuthenticated && checkAuthentication(dispatch)) {
    return null;
  }
  return <BaseLayout isAuthenticated={isAuthenticated} />;
};

const App = () => (
  <IntlPolyfill locales={['en', 'de']} fallback={<div>Preparing language, please wait...</div>}>
    <Localization>
      <Router>
        <Provider>
          <Authentication />
        </Provider>
      </Router>
    </Localization>
  </IntlPolyfill>
);

export default App;
