import React, { useEffect } from 'react';

import { useLoginState } from '../../../actions';

import Header from '../Header';
import ManageProjectModal from '../manage/ManageProject.modal';
import ConfirmModal from '../commons/Confirm.modal';

import { Provider, useProjectsState, loadProject, deleteProject, restoreProject } from '../actions';
import ManageCountries from './ManageCountries';
import UploadVouchers from './UploadVouchers';
import Vouchers from './Vouchers';

const buildDeleteAction = ({ deletedAt, slug }, dispatch) => ({
  name: 'Delete',
  action: (
    <ConfirmModal
      className="btn btn-sm btn-danger"
      buttonLabel={deletedAt ? 'Restore' : 'Delete'}
      question={`Are you sure you want to ${deletedAt ? 'restore' : 'delete'} this project?`}
      confirm={() => (deletedAt ? restoreProject : deleteProject)(slug, dispatch)}
    />
  )
});

const ViewProject = ({ projectSlug, user }) => {
  const [{ project }, dispatch] = useProjectsState();

  useEffect(() => {
    loadProject(projectSlug, dispatch);
  }, [dispatch, projectSlug]);

  let actions;
  if (user.admin) {
    actions = [
      buildDeleteAction(project, dispatch),
      {
        name: 'Edit',
        action: <ManageProjectModal edit={project} className="btn btn-sm btn-info" />
      }
    ];
  }

  return (
    <>
      <Header title={`Project: ${project.name}`} back actions={actions} />

      {project.slug === projectSlug && (
        <div className="body">
          <div className="container">
            <div className="row">
              <div className="col">
                <ManageCountries projectSlug={projectSlug} />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col">
                <UploadVouchers projectSlug={projectSlug} />
              </div>
            </div>
            <div className="row mt-5">
              <div className="col">
                <Vouchers projectSlug={projectSlug} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const ViewProjectState = ({
  match: {
    params: { id: projectSlug }
  }
}) => {
  const [{ user }] = useLoginState();
  return (
    <Provider>
      {projectSlug && Object.keys(user).length && (
        <ViewProject projectSlug={projectSlug} user={user} />
      )}
    </Provider>
  );
};

export default ViewProjectState;
