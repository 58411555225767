import React from 'react';
import PropTypes from 'prop-types';

const Voucher = ({ code }) => (
  <div className="voucher">
    <div className="voucher-font">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        viewBox="0 0 150 43"
        width="100%"
        height="100%"
      >
        <path />
        <rect
          width="100%"
          height="100%"
          style={{ fill: 'rgb(242, 243, 244)', strokeWidth: 5, stroke: 'rgb(0,0,0)' }}
        />
        <text x="14" y="27">
          {code}
        </text>
      </svg>
    </div>
  </div>
);

Voucher.propTypes = {
  code: PropTypes.string.isRequired
};

export default Voucher;
