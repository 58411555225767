import axios from 'axios';
import { history } from './router';

axios.interceptors.response.use(
  response => response,
  error => {
    const handle = title => {
      // const err = error.response.data.message;
      // message.error(
      //   <span>
      //     {title || 'An error occurred!'}
      //     <br />
      //     {err && <Alert className="mt-3" message={err} type="error" />}
      //   </span>
      // );
    };
    const err = { response: { data: error.response.data } };
    switch (error.response.status) {
      case 401:
        window.location = '/login';
        return undefined;
      case 403:
        history.replace('/forbidden');
        return Promise.reject(err);
      case 404:
        handle('Resource not found');
        history.replace('/not-found');
        return Promise.reject(err);
      case 500:
        handle('An internal server error occurred');
        return Promise.reject(err);

      default:
        if (error.response.status === 400 && error.response.data.error) {
          handle(`Error: ${error.response.data.error}`);
          return Promise.reject(err);
        }
        return Promise.reject(error);
    }
  }
);
