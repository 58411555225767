import React from 'react';

export default () => {
  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="d-flex">
          <div className="display-3 mr-3">404</div>
          <div>
            <h4 className="pt-3 mb-1">NOT FOUND</h4>
            <p className="text-muted">The resource you are looking for was not found.</p>
          </div>
        </div>
      </div>
    </div>
  );
};
