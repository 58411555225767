import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { addProject, editProject, useProjectsState } from '../actions';

import useUploadImage from './UploadImage';

import Input from '../commons/Input';

const slugify = value =>
  value
    .toLowerCase()
    .replace(/[^a-z0-9 ]/g, '')
    .replace(/ /g, '-');

const ManageProject = ({ edit, history, close }) => {
  const [state, setState] = useState(
    edit || { name: '', slug: '', contact: '', androidStoreLink: '' }
  );
  const [image, imageInput] = useUploadImage(state.appIcon, state.slug);
  const [previousSlug] = useState(edit && edit.slug);
  const [{ project, errors }, dispatch] = useProjectsState();
  const hasErrors = Object.keys(errors).length;
  const isEdit = !!edit;

  const added = !isEdit && project.updatedAt;
  const updated = isEdit && project.updatedAt !== state.updatedAt;
  const slugChanged = (added || updated) && previousSlug !== project.slug;
  useEffect(() => {
    if (slugChanged) {
      history.push(`/${state.slug}`);
    }
  }, [history, slugChanged, state.slug]);

  useEffect(() => {
    if (updated) {
      close();
    }
  }, [close, updated]);

  useEffect(() => {
    if (hasErrors && state.disabled) {
      setState(state => ({ ...state, disabled: false }));
    }
  }, [hasErrors, state.disabled]);

  const onChange = ({ target: { name, value, checked } }) => {
    if (name === 'name') {
      if (!isEdit) {
        setState(state => ({ ...state, name: value, slug: slugify(value.trim()) }));
      } else {
        setState(state => ({ ...state, name: value }));
      }
    } else if (name === 'slug') {
      setState(state => ({ ...state, slug: slugify(value) }));
    } else if (name === 'formallyLanguage') {
      setState(state => ({ ...state, formallyLanguage: checked }));
    } else {
      setState(state => ({ ...state, [name]: value }));
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    const { _id, name, slug, contact, androidStoreLink, formallyLanguage } = state;
    setState(state => ({ ...state, disabled: true }));
    let file;
    if (image) {
      file = new FormData();
      file.append('file', image);
    }
    (edit ? editProject : addProject)(
      { _id, name, slug, contact, androidStoreLink, formallyLanguage },
      file,
      dispatch
    );
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="container">
        <Input name="name" label="Name" data={state} errors={errors} onChange={onChange} />
        <Input name="slug" label="Slug" data={state} errors={errors} onChange={onChange} />
        <Input
          name="contact"
          label="Contact address"
          data={state}
          errors={errors}
          onChange={onChange}
        />
        <Input
          name="androidStoreLink"
          label="Android store link"
          data={state}
          errors={errors}
          onChange={onChange}
        />
        <Input
          name="formallyLanguage"
          label="Use formally language"
          data={state}
          errors={errors}
          onChange={onChange}
          type="checkbox"
          checked={!!state.formallyLanguage}
        />
        <div className="row">
          <div className="w-100">
            <span className="d-inline-block w-50">App icon:</span>
            {imageInput}
          </div>
        </div>
        <div className="row pt-3">
          <button
            className="btn btn-sm btn-primary btn-block"
            type="submit"
            disabled={state.disabled || (!state.name || !state.slug || !state.androidStoreLink)}
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

ManageProject.propTypes = {
  close: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  edit: PropTypes.object
};

ManageProject.defaultProps = {
  edit: undefined
};

export default withRouter(ManageProject);
