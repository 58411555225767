import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

const Android = ({ androidStoreLink, text }) => (
  <a
    className="download-link btn btn-link"
    href={androidStoreLink}
    target="_blank"
    rel="noopener noreferrer"
  >
    <img src="/images/google-play-store.svg" alt={text('google')} />
  </a>
);

const AppleiOs = ({ onClick, text }) => (
  <button type="button" className="download-link btn btn-link" onClick={onClick}>
    <img src="/images/apple-app-store.svg" alt={text('apple')} />
  </button>
);

const Start = ({ name, slug, appIcon, androidStoreLink, onClick, intl: { formatMessage } }) => {
  const text = (id, values = {}) => formatMessage({ id: `landing.download.${id}` }, values);
  return (
    <div className="body step-1">
      {!!appIcon && (
        <div className="app-icon">
          <img src={`/api/projects/${slug}/icon`} alt={name} />
        </div>
      )}
      <div className="headline">
        {text('title', {
          name: (
            <span key="name" className="name">
              {name}
            </span>
          )
        })}
      </div>
      <div className="links">
        <AppleiOs projectSlug={slug} onClick={onClick} text={text} />
        <Android androidStoreLink={androidStoreLink} text={text} />
      </div>
    </div>
  );
};

Start.propTypes = {
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  androidStoreLink: PropTypes.string.isRequired
};

export default injectIntl(Start);
