import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import Select from 'react-select';

import Voucher from '../../voucher/Voucher';
import CopyToClipboard from './CopyToClipboard';

const GetVoucher = ({ claimVoucher, countries, locale }) => {
  const [disabled, setDisabled] = useState(false);
  const [voucher, setVoucher] = useState();
  const [country, setCountry] = useState(countries[0].key);
  const noMoreVouchers = voucher === false;
  const hasVoucher = !disabled && !!voucher;

  const onClick = () => {
    setDisabled(true);
    claimVoucher(country).then(setVoucher);
    setTimeout(() => {
      setDisabled(false);
    }, 700);
  };

  const step = hasVoucher ? 'success' : 'generate';
  const buttonMessage = <FormattedMessage id={`landing.voucher.${step}.button`} />;
  let content;
  if (hasVoucher) {
    content = (
      <>
        <Voucher code={voucher.value} />
        <CopyToClipboard
          text={voucher.value}
          imgSrc="/images/icons-copy.svg"
          buttonMessage={buttonMessage}
        />
      </>
    );
  } else {
    const options = countries
      .filter(({ disabled }) => !disabled)
      .map(country => ({ value: country.key, label: country[locale] }));
    content = (
      <>
        <div className="country-select">
          <FormattedMessage id="landing.voucher.generate.country" />
          <Select
            className="select-field"
            type="select"
            name="country"
            id="country"
            value={options.find(({ value }) => value === country)}
            onChange={({ value }) => setCountry(value)}
            options={options}
          />
        </div>
        <Button color="primary" onClick={onClick} disabled={disabled || noMoreVouchers || !country}>
          {buttonMessage}
        </Button>
      </>
    );
  }

  return (
    <div className="get-voucher">
      <div className="title">
        <FormattedMessage id={`landing.voucher.${noMoreVouchers ? 'error' : step}.title`} />
      </div>
      {content}
    </div>
  );
};

GetVoucher.propTypes = {
  claimVoucher: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  locale: PropTypes.string.isRequired
};

export default GetVoucher;
