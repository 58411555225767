import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import GetVoucher from './tutorial/GetVoucher';

const Step = ({ step, text, locale, image }) => {
  const img = image || `step-${step}/${locale}`;
  const imgSrc = `/images/tutorial/${img}`;
  const altText = text(step, 'image-alt');
  return (
    <div className="step d-md-flex">
      <div className="data">
        <div className="title">{text(step, 'title')}</div>
        <div className="text">{text(step, 'text')}</div>
      </div>
      <div className={`image${image ? ' with-background' : ''}`}>
        {image ? (
          <div>
            <img src={imgSrc} alt={altText} />
          </div>
        ) : (
          <img
            src={`${imgSrc}.png`}
            srcSet={`${imgSrc}@2x.png 2x, ${imgSrc}@3x.png 3x`}
            alt={altText}
          />
        )}
      </div>
    </div>
  );
};

const Tutorial = ({
  name,
  slug,
  contact,
  claimVoucher,
  countries,
  intl: { formatMessage, locale }
}) => {
  const text = (step, id) => formatMessage({ id: `landing.step${step}.${id}` });
  const text4 = (step, id) => {
    if (id === 'text') {
      return (
        <FormattedMessage
          id={`landing.step${step}.${id}`}
          values={{
            x: msg => <span className="step-option">{msg}</span>,
            or: msg => <span className="step-option-or">{msg} </span>
          }}
        />
      );
    }
    return text(step, id);
  };
  return (
    <div className="body step-2">
      <div className="headline">
        <FormattedMessage
          id="landing.tutorial"
          values={{ name: <span className="name">{name}</span> }}
        />
      </div>
      <div className="steps">
        <Step step="1" text={text} locale={locale} image="app-store-icon.svg" />
        <Step step="2" text={text} locale={locale} />
        <Step step="3" text={text} locale={locale} />
        <GetVoucher
          claimVoucher={country => claimVoucher(slug, country)}
          countries={countries}
          locale={locale}
        />
        <Step step="4" text={text4} locale={locale} />
        <div className="step">
          <div className="data">
            <div className="title">
              <FormattedMessage id="landing.tutorial.help.title" />
            </div>
            <div className="text">
              <FormattedMessage
                id="landing.tutorial.help.text"
                values={{ name: <span className="name">{name}</span> }}
              />
            </div>
            <div className="text mt-4">
              <FormattedMessage id="landing.tutorial.help.contact" />: {contact}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Tutorial.propTypes = {
  claimVoucher: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired
};

export default injectIntl(Tutorial);
