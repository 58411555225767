import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';

import Input from '../commons/Input';

import { useProjectsState, editProject } from '../actions';

const ManageCountries = ({ projectSlug }) => {
  const [{ project }, dispatch] = useProjectsState();
  const [country, setCountry] = useState(undefined);
  const { countries = [] } = project;

  let newCountry;
  if (country) {
    const onChange = ({ target: { name, value, checked } }) => {
      if (name === 'disabled') {
        setCountry(data => ({ ...data, [name]: !checked }));
      } else if (name === 'key') {
        setCountry(data => ({ ...data, [name]: value.toLowerCase().replace(/[^a-z0-9-]/g, '') }));
      } else {
        setCountry(data => ({ ...data, [name]: value }));
      }
    };

    const errors = {};

    if (
      countries.find(
        ({ key }, mainIndex) =>
          key === country.key && (country.index === undefined || country.index !== mainIndex)
      )
    ) {
      errors.key = 'Country already exists';
    } else {
      delete errors.key;
    }

    const isNew = country.index === undefined;

    const saveCountry = () => {
      const data = { ...project };

      if (isNew) {
        data.countries = [...countries, country];
      } else {
        data.countries = countries.map(existing => {
          if (country.key === existing.key) {
            return country;
          }
          return existing;
        });
      }

      editProject(data, undefined, dispatch);

      setCountry(undefined);
    };

    newCountry = (
      <form method="post" action="#" id="#" className="mb-5">
        <div className="container">
          <Input
            label="Country key"
            name="key"
            placeholder="Unique identifier (e.g. country name)"
            data={country}
            errors={errors}
            onChange={onChange}
          />
          <Input
            label="Display name german"
            name="de"
            placeholder="German name"
            data={country}
            errors={errors}
            onChange={onChange}
          />
          <Input
            label="Display name english"
            name="en"
            placeholder="English name"
            data={country}
            errors={errors}
            onChange={onChange}
          />
          <Input
            name="disabled"
            label="Enable language"
            data={{ disabled: !country.disabled }}
            errors={errors}
            onChange={onChange}
            type="checkbox"
            checked={!country.disabled}
          />
        </div>
        <div className="d-flex flex-row">
          <button
            type="button"
            className="btn btn-block btn-primary"
            disabled={!country.key || !country.de || !country.en || !!Object.keys(errors).length}
            onClick={saveCountry}
          >
            {isNew ? 'Add new' : 'Save'} country
          </button>
          <span className="mr-2" />
          <button
            type="button"
            className="btn btn-block btn-secondary"
            onClick={() => setCountry(undefined)}
          >
            Cancel
          </button>
        </div>
      </form>
    );
  }

  return (
    <div className="countries-list">
      <p className="d-flex flex-row justify-content-between">
        Countries
        <button
          type="button"
          className="btn btn-sm btn-primary"
          disabled={country}
          onClick={() => setCountry({})}
        >
          Add country
        </button>
      </p>
      {newCountry}
      <Table responsive hover>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Key</th>
            <th scope="col">German</th>
            <th scope="col">English</th>
            <th scope="col">Enabled</th>
          </tr>
        </thead>
        <tbody>
          {countries.map(({ key, de, en, disabled = false }, index) => (
            <tr
              key={key}
              className={disabled ? 'text-muted' : ''}
              onClick={() => setCountry({ key, de, en, disabled, index })}
            >
              <th scope="row">{index + 1}</th>
              <td>{key}</td>
              <td>{de}</td>
              <td>{en}</td>
              <td>{disabled ? 'No' : 'Yes'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

ManageCountries.propTypes = {
  projectSlug: PropTypes.string.isRequired
};

export default ManageCountries;
