import React from 'react';
import PropTypes from 'prop-types';

const Input = ({ name, label, data, errors, onChange, ...rest }) => {
  const error = errors[name];
  return (
    <div className="form-group row">
      <label className="col-form-label col-12 col-lg-6 pl-0" htmlFor={name}>
        {label}:
      </label>
      <input
        className="form-control col-12 col-lg-6"
        id={name}
        name={name}
        value={data[name] || ''}
        onChange={onChange}
        autoComplete="off"
        {...rest}
      />
      {error && <p className="text-danger">{error}</p>}
    </div>
  );
};

Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

export default Input;
