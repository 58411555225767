import axios from 'axios';

const buildUserUuid = userId => {
  if (userId) {
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = (Math.random() * 16) | 0; // eslint-disable-line no-bitwise
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16); // eslint-disable-line no-bitwise
    });
    userId = String(userId);
    return `${uuid.substr(0, 24)}f${userId}a${uuid.substr(25 + userId.length + 1)}`;
  }
  return undefined;
};

const setAuthToken = (token, decoded) => {
  if (token) {
    axios.defaults.headers.common.Authorization = token;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
  if (decoded) {
    axios.defaults.headers.common['x-request-id'] = buildUserUuid(decoded._id);
  } else {
    delete axios.defaults.headers.common['x-request-id'];
  }
};

export default setAuthToken;
