import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import GetVoucher from './tutorial/GetVoucher';

const VoucherOnly = ({
  slug,
  claimVoucher,
  countries,
  intl: { locale }
}) => {
  return (
    <div className="body step-2">
      <div className="steps">
        <GetVoucher
          claimVoucher={country => claimVoucher(slug, country)}
          countries={countries}
          locale={locale}
        />
      </div>
    </div>
  );
};

VoucherOnly.propTypes = {
  claimVoucher: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  slug: PropTypes.string.isRequired
};

export default injectIntl(VoucherOnly);
