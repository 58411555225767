import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { debounce } from 'throttle-debounce';
import { Progress } from 'reactstrap';
import Select from 'react-select';

import { useProjectsState, getVoucherCounts } from '../actions';

import './files.css';

const updateDebounced = debounce(333, callback => callback());

const getProcessStatus = processId =>
  axios.get(`/api/vouchers/status?processId=${processId}`).then(({ data }) => data);

const MAX_FILE_SIZE = 10 * 1000 * 1000;

const check = {
  type: ({ name }) => name.indexOf('.xls') !== name.length - 4 && 'File has unsupported format',
  size: ({ size, name }) => size > MAX_FILE_SIZE && 'File is too large'
};

const Process = ({ results }) => {
  if (!results || results.open.total === undefined || results.redeemed.total === undefined) {
    return null;
  }
  const { open, redeemed } = results;
  const progress = (name, { progress, total }) => {
    if (!total) {
      return undefined;
    }
    const percent = Math.min(Math.round((progress * 100) / total), 100);
    return (
      <div>
        <p>
          {name}: {progress}/{total}
        </p>
        <Progress color="success" value={percent}>
          {percent}%
        </Progress>
      </div>
    );
  };
  return (
    <div>
      {progress('Open', open)}
      {progress('Redeemed', redeemed)}
    </div>
  );
};

const UploadVouchers = ({ projectSlug }) => {
  const [
    {
      project: { countries = [] }
    },
    dispatch
  ] = useProjectsState();

  const [selected, setSelected] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [processId, setProcessId] = useState();
  const [process, setProcess] = useState({});
  const [country, setCountry] = useState(countries[0].key);

  useEffect(() => {
    if (processId && process) {
      if (!process.done) {
        updateDebounced(() => getProcessStatus(processId).then(result => setProcess(result)));
      } else if (process.done) {
        getVoucherCounts(projectSlug, dispatch);
        setSelected();
        setProcessId();
        setProcess({});
      }
    }
  }, [processId, process, projectSlug, dispatch]);

  const onSelect = ({ target }) => {
    const {
      files: [file]
    } = target;
    const error = check.type(file) || check.size(file);
    setErrorMessage(error);
    if (error) {
      target.value = null;
    } else {
      setSelected(file);
    }
  };

  const onClick = () => {
    const data = new FormData();
    data.append('file', selected);
    axios
      .post(`/api/projects/${projectSlug}/vouchers?country=${country}`, data, {})
      .then(({ data: { processId } }) => setProcessId(processId));
  };

  if (processId) {
    return (
      <div>
        <p>Processing file...</p>
        {process && <Process results={process.results} />}
      </div>
    );
  }

  const options = countries.map(country => ({ value: country.key, label: country.en }));

  return (
    <form method="post" action="#" id="#">
      <div className="form-group files color">
        <p>Upload voucher codes</p>
        <Select
          className="mb-2"
          type="select"
          name="country"
          id="country"
          value={options.find(({ value }) => value === country)}
          onChange={({ value }) => setCountry(value)}
          options={options}
        />
        <input type="file" name="file" onChange={onSelect} />
      </div>
      {errorMessage && <p className="text-danger">Error: {errorMessage}</p>}
      <button
        type="button"
        className={`btn btn-block ${selected ? 'btn-success' : 'btn-secondary'}`}
        disabled={!country || !selected}
        onClick={onClick}
      >
        Upload
      </button>
    </form>
  );
};

UploadVouchers.propTypes = {
  projectSlug: PropTypes.string.isRequired
};

export default UploadVouchers;
