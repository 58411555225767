import React from 'react';

import Header from './Header';
import ProjectList from './projects/ProjectList';
import ManageProjectModal from './manage/ManageProject.modal';

import { Provider } from './actions';

const actions = [
  { name: 'Add project', action: <ManageProjectModal className="btn btn-sm btn-info" /> }
];

const Landing = () => (
  <Provider>
    <Header title="Projects" actions={actions} />
    <div className="body">
      <ProjectList />
    </div>
  </Provider>
);

export default Landing;
