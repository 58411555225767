import React from 'react';

export default () => {
  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="d-flex">
          <div className="display-3 mr-3">403</div>
          <div>
            <h4 className="pt-3 mb-1">FORBIDDEN</h4>
            <p className="text-muted">You are not allowed to access the requested resource.</p>
          </div>
        </div>
      </div>
    </div>
  );
};
