import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

const ConfirmModal = ({ buttonLabel, question, confirm, className }) => {
  const [visible, setVisible] = useState(false);

  const toggle = () => setVisible(visible => !visible);

  return (
    <>
      <Button onClick={() => setVisible(true)} color="primary" className={className}>
        {buttonLabel}
      </Button>
      <Modal isOpen={visible} toggle={toggle} backdrop="static" centered>
        <ModalHeader
          toggle={toggle}
          close={
            <button type="button" className="close" onClick={toggle}>
              <i className="zmdi zmdi-close" />
            </button>
          }
        >
          {question}
        </ModalHeader>
        <ModalBody className="d-flex justify-content-end">
          <Button color="secondary" onClick={toggle} className="mr-2">
            No
          </Button>
          <Button
            color="danger"
            onClick={() => {
              confirm();
              toggle();
            }}
          >
            Yes
          </Button>
        </ModalBody>
      </Modal>
    </>
  );
};

ConfirmModal.propTypes = {
  confirm: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  className: PropTypes.string
};

ConfirmModal.defaultProps = {
  className: undefined
};

export default ConfirmModal;
