import React, { useState } from 'react';

import './app-icon-upload.css';

const MAX_FILE_SIZE = 250 * 1000;

const imageTypes = ['jpg', 'jpeg', 'png', 'svg'];

const check = {
  type: ({ name }) => {
    const [ending] = name.split('.').reverse();
    return imageTypes.indexOf(ending.toLowerCase()) === -1 && 'File has unsupported format';
  },
  size: ({ size, name }) => size > MAX_FILE_SIZE && 'File is too large'
};

const useUploadImage = (appIcon, projectSlug) => {
  const [selected, setSelected] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const onSelect = ({ target }) => {
    const {
      files: [file]
    } = target;
    const error = check.type(file) || check.size(file);
    setErrorMessage(error);
    if (error) {
      target.value = null;
    } else {
      setSelected(file);
    }
  };

  let component;
  if (selected || appIcon) {
    const src = selected ? URL.createObjectURL(selected) : `/api/projects/${projectSlug}/icon`;
    const name = selected ? selected.name : 'App icon';
    component = (
      <div className="form-group app-icon-upload color d-flex">
        <input type="file" name="file" onChange={onSelect} />
        <img src={src} alt={name} />
      </div>
    );
  } else {
    component = (
      <div className="form-group app-icon-upload color">
        <input type="file" name="file" onChange={onSelect} />
      </div>
    );
  }

  return [
    selected,
    <>
      {component}
      {errorMessage && <p className="text-danger">Error: {errorMessage}</p>}
    </>
  ];
};

export default useUploadImage;
